<!--
 * @Descripttion:
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-03-13 17:22:04
-->
<template>
    <div class="blueBg">
        <div class="back" @click="$router.push('/')">
            <van-icon name="arrow-left" color="#fff" size="0.45rem" />
            返回首页
        </div>
        <img src="/static/img/intro/intro-bg.png" class="w-100">
        <div class="intro-title mar-l" style="padding-right:0.4rem">
            <h3>用户如何创建</h3>
            <h2>评标专业库？</h2>
        </div>
        <div class="intro-pad">
            <div class="intro-white">
                <div class="center">
                    <img src="/static/img/intro/xia.png" class="w12" style="position: relative; top:-0.5rem ">
                </div>
                <div class="question-title">
                    <p class="numBg">1</p>
                    <p>点击底部标签栏的<span class="fc">用户中心</span>图标进入功能页面。</p>
                </div>
                <img src="/static/img/intro/zhuanyeku/1.png" class="w-100">

                <div class="question-title">
                    <p class="numBg">2</p>
                    <p>点击<span class="fc">评审专业</span>，进行评审专业设置。</p>
                </div>
                <img src="/static/img/intro/zhuanyeku/2.png" class="w-100">

                <div class="question-title">
                    <p class="numBg">3</p>
                    <p>点击底部的<span class="fc">修改评审专业</span>按钮，添加或修改评审专业。</p>
                </div>
                <img src="/static/img/intro/zhuanyeku/3.png" class="w-100">

                <div class="question-title">
                    <p class="numBg">4</p>
                    <p><span class="fc">勾选评审专业</span>，设置评标专业库。</p>
                </div>
                <img src="/static/img/intro/zhuanyeku/4.png" class="w-100">

                <div class="question-title">
                    <p class="numBg">5</p>
                    <p>点击<span class="fc">保存选择</span>，生成评审专业库。</p>
                </div>
                <img src="/static/img/intro/zhuanyeku/5.png" class="w-100">
                <div class="center">
                    <img src="/static/img/intro/shang.png" class="w12" style="position: relative; bottom:-0.7rem">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Icon } from 'vant'
export default {
    data(){
        return{
        }
    },

    components:{
      [Icon.name]:Icon
    }
}
</script>

<style scoped>
  .back{
    position: absolute;
    top: 0.3rem;
    left:0.5rem;
    padding: 0.1rem 0.3rem 0.1rem 0.2rem;
    border-radius: 0.6rem;
    background: #009A86;
    display: flex;
    align-items: center;
    color: #fff;
  }
</style>
