import { render, staticRenderFns } from "./Zhuanyeku.vue?vue&type=template&id=25ed6182&scoped=true&"
import script from "./Zhuanyeku.vue?vue&type=script&lang=js&"
export * from "./Zhuanyeku.vue?vue&type=script&lang=js&"
import style0 from "./Zhuanyeku.vue?vue&type=style&index=0&id=25ed6182&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25ed6182",
  null
  
)

export default component.exports